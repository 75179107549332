// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Fonts
$font-text-regular: 'BentonSans Regular';
$font-text-medium: 'BentonSans Medium';
$font-text-bold: 'BentonSans Bold';
$font-text-black: 'BentonSans Black';
$font-bold-italic: 'Playfair Display BoldItalic';
$font-black-italic: 'Playfair Display BlackItalic';

// Color
$color-text: #000;
$color-red: #d51317;
$color-dark-red: #b1092a;
$color-decoration-blue: #00add5;
$color-underlined:  rgba(213, 19, 23, 0.2);
$color-text-grey: #bbb5a7;
$color-menu-gray: #f8f7f6;
$color-book-gray: #d8d8d8;
$color-footer: #2a2a2a;
$color-box-gray: rgba(187, 181, 167, 0.1);
$color-box-framed : rgba(213, 19, 23, 0.1);

// Size
$header-right-width : 120px;
