#footer{
    width: 100%;
    margin-top: 0;
    padding-top: 48px;
    padding-right: $header-right-width;
    padding-bottom: 56px;
    background-color: $color-footer;
    color: #fff;
    .title-footer{
        font-family: $font-text-bold;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.5;
        margin-bottom: 24px;
        color: $color-red;
    }
    .contact-footer{
        .contact{
            display: block;
            font-family: $font-text-regular;
            font-size: 1rem;
            font-weight: normal;
            line-height: 24px;
            color: #fff;
            &:hover{
                color: #fff;
                text-decoration: none;
            }
        }
        span{
            display: block;
        }
        .city{
            display: inline-block;
        }
    }
    .nav-valorize{
        ul{
            padding: 0;
        }
    }
    .nav-footer{
        ul{
            padding: 0;
        }
        li{
            list-style-type: none;
            margin-bottom: 12px;
        }
        a{
            font-family: $font-text-regular;
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.5;
            color: #fff;
        }
    }
}
