h1 {
    font-family: $font-text-bold;
    font-size: 3rem;
    font-weight: normal;
    line-height: normal;
    color: $color-red;
    margin-bottom: 64px;
    &:after {
        content: "";
        display: block;
        width: 24px;
        height: 4px;
        background-color: #BBB5A7;
        margin-top: 15px;
    }
}

h2 {
    font-family: $font-text-black;
    font-weight: normal;
    font-size: 2rem;
    color: #000;
    margin-top: 0;
    margin-bottom: 40px;
}

form {
    .field {
        margin-bottom: 40px;
        &.error {
            .input {
                position: relative;
                &:before {
                    content: "";
                    background-image: url(../images/input-error.svg);
                    background-size: 100%;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: 13px;
                    right: 20px;
                }
            }
        }
        &.valid {
            .input {
                position: relative;
                &:before {
                    content: "";
                    background-image: url(../images/input-valid.svg);
                    background-size: 100%;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: 13px;
                    right: 20px;
                }
            }
        }
        label {
            display: block;
            font-family: $font-text-medium;
            font-weight: normal;
            font-size: 1.5rem;
            color: #000;
            margin-bottom: 15px;
            strong {
                font-family: $font-text-bold;
                font-weight: normal;
                color: $color-red;
            }
        }
        p {
            font-family: $font-text-regular;
            font-weight: normal;
            color: #000;
            margin-top: 0;
        }
        input {
            font-family: $font-text-regular;
            font-weight: normal;
            font-size: 0.875rem;
            color: #000;
            padding: 16px 50px 16px 24px;
            border: 1px solid $color-red;
            display: block;
            width: 100%;
            border-radius: 4px;
            outline-color: $color-red;
        }
        .form-error {
            ul {
                list-style-type: none;
                padding-left: 0;
                margin-top: 5px;
                li {
                    display: block;
                    font-family: $font-text-regular;
                    font-weight: normal;
                    font-size: 0.875rem;
                    color: $color-red;
                    margin-bottom: 5px;
                }
            }
        }
    }
    input[type=checkbox] {
        opacity: 0;
        position: absolute;
        z-index: -1;
        &:checked {
            & + label {
                &:before {
                    background-image: url(../images/checkbox-checked.svg);
                }
            }
        }
        & + label {
            position: relative;
            padding-left: 30px;
            &:before {
                content: "";
                background-image: url(../images/checkbox.svg);
                background-size: 100%;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
            }
        }
    }
    button[type=submit] {
        font-family: $font-text-bold;
        font-weight: normal;
        font-size: 0.875rem;
        color: #fff;
        background-color: $color-red;
        padding: 15px 25px;
        border: 0;
        border-radius: 4px;
        margin-top: 40px;
        transition: all 300ms ease;
        cursor: pointer;
        &:hover {
            background-color: $color-dark-red;
        }
        &:disabled {
            background-color: $color-book-gray;
            cursor: not-allowed;
        }
    }
    .conditions {
        label {
            display: block;
            margin-bottom: 24px;
        }
    }
}

.loader {
    margin-top: 40px;
    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        background-image: url(../images/loader.gif);
        background-size: 100%;
        width: 48px;
        height: 48px;
    }
    p {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 60px);
        font-size: 0.875rem;
    }
}

.global-error {
    border: 8px solid #FF0000;
    padding: 40px;
    margin-top: 40px;
    p {
        margin: 0;
    }
}
