@font-face {
    font-family: 'BentonSans Regular';
    src: url('/build/fonts/bentonsans_regular-webfont.woff2') format('woff2'),
         url('/build/fonts/betonsans_regular-webfont.woff') format('woff'),
         url('/build/fonts/BentonSans.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans Medium';
    src: url('/build/fonts/bentonsans_medium-webfont.woff2') format('woff2'),
         url('/build/fonts/betonsans_medium-webfont.woff') format('woff'),
         url('/build/fonts/BentonSans-Medium.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans Bold';
    src: url('/build/fonts/bentonsans_bold-webfont.woff2') format('woff2'),
         url('/build/fonts/betonsans_bold-webfont.woff') format('woff'),
         url('/build/fonts/BentonSans-Bold.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans Black';
    src: url('/build/fonts/bentonsans_black-webfont.woff2') format('woff2'),
         url('/build/fonts/betonsans_black-webfont.woff') format('woff'),
         url('/build/fonts/BentonSans-Black.eot');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Playfair Display BoldItalic';
    src: url('/build/fonts/playfairdisplay-bolditalic-webfont.woff2') format('woff2'),
         url('/build/fonts/playfairdisplay-bolditalic-webfont.woff') format('woff'),
         url('/build/fonts/PlayfairDisplay-BoldItalic.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display BlackItalic';
    src: url('/build/fonts/playfairdisplay-blackitalic-webfont.woff2') format('woff2'),
         url('/build/fonts/playfairdisplay-blackitalic-webfont.woff') format('woff'),
         url('/build/fonts/PlayfairDisplay-BlackItalic.eot');
    font-weight: normal;
    font-style: normal;
}
