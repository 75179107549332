header.banner{
    padding-right: 120px;
    .banner-content{
        max-width: 1320px;
        margin: 0 auto;
        background-color: #fff;
    }
    .brand{
        display: block;
        background-image: url(../images/epf-logo.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 127px;
        height: 96px;
        margin-left: 40px;
        text-indent: -999px;
    }
    .logo-fixed {
        opacity: 0;
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .navigation-mobile{
        display: none;
        #ml-menu{
            z-index: -1;
            top: 50px;
            background-color: $color-menu-gray;
            overflow: auto;
            ul.level-1{
                .menu__item{
                    &:first-child,
                    &:nth-child(2),
                    &:nth-child(3){
                        .menu__link{
                            font-family: $font-text-bold;
                        }
                    }
                }
            }
            .menu__breadcrumbs{
                position: relative;
                &.empty {
                    display: none;
                }
                a{
                    display: block;
                    font-family: $font-text-bold;
                    font-size: 0.875rem;
                    font-weight: normal;
                    line-height: normal;
                    color: #000;
                    padding-left: 30px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $color-red;
                    outline: none;
                    text-transform: none;
                    letter-spacing: normal;
                    &:first-of-type {
                        position: absolute;
                        border: 0;
                        top: 25px;
                        left: 25px;
                        padding-left: 0;
                    }
                    &.last-item-breadrumb {
                        color: #bbb5a7;
                        border: 0;
                        margin-top: 20px;
                        position: relative;
                        &:after {
                            content: "";
                            font-family: none;
                            width: 10px;
                            height: 10px;
                            position: absolute;
                            right: 0;
                            top: 3px;
                            padding: .25em 1.25em;
                            background-image: url(../images/arrow-menu.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
            .menu__wrap{
                bottom: auto;
                min-height: 81vh;
                outline: none;
            }
            .menu__level{
                width: 100%;
                margin-top: 70px;
                &.level-1.menu__level--current {
                    margin-top: 0;
                }
                &.level-3 {
                    margin-top: 115px;
                }
                .menu__item{
                    margin-left: 20px;
                    margin-right: 20px;
                    border-bottom: 1px solid $color-red;
                }
            }
            [data-menu^=submenu-]{
                .menu__item{
                    border-bottom: none;
                }
            }
            .menu__link{
                font-family: $font-text-regular;
                font-size: 0.875rem;
                font-weight: normal;
                padding: 1em 2.5em 1em 0.5em;
                color: #000;
                &[data-submenu]{
                    &:after {
                        content: "";
                        font-family: none;
                        width: 24px;
                        height: 12px;
                        background-image: url(../images/arrow-menu.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        display: inline-block;
                        vertical-align: -3px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .twitter-logo{
            position: absolute;
            bottom: 0;
            left: calc(50% - 120px);
            display: inline-block;
            width: 25px;
            height: 25px;
            background-image: url(../images/twitter.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
        .youtube-logo{
            position: absolute;
            bottom: 0;
            left: calc(50% - 45px);
            display: inline-block;
            width: 25px;
            height: 25px;
            background-image: url(../images/youtube.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
        .facebook-logo{
            position: absolute;
            bottom: 0;
            left: calc(50% + 30px);
            display: inline-block;
            width: 25px;
            height: 25px;
            background-image: url(../images/facebook.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
        .instagram-logo{
            position: absolute;
            bottom: 0;
            left: calc(50% + 90px);
            display: inline-block;
            width: 25px;
            height: 25px;
            background-image: url(../images/instagram.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .nav-first{
        .wp-megamenu-wrap .wpmm-nav-wrap > ul.wp-megamenu > li.wpmm_mega_menu:hover > .wpmm-strees-row-and-content-container > ul.wp-megamenu-sub-menu{
            animation-name:none;
            animation-duration: 0;
            display: none;
            top: auto;
            opacity: 0;
            z-index: -1;
            visibility: hidden;
        }
        .menu--open{
            display: block!important;
            top: 25px!important;
            opacity: 1!important;
            z-index: 2!important;
            visibility: visible!important;
        }
        .submenu--open{
            display: block!important;
            position: absolute!important;
            left: auto!important;
            right: -200px!important;
            width: 288px!important;
        }
    }
    #wp-megamenu-first_navigation{
        z-index: 5;
        padding: 0;
        background: none;
        &:hover {
            z-index: 5!important;
        }
        &.wpmm-sticky.wpmm-sticky-wrap{
            position: static!important;
        }
        .wpmm-strees-row-and-content-container{
            position: relative;
            top: 70px;
        }
        .wpmm-social-link-search{
            display: none;
        }
        .wpmm_brand_logo_wrap{
            display: none;
        }
        .wpmm-row{
            box-shadow: 5px 5px 6px 0.5px rgba(0,0,0, 0.1);
            &:first-child{
                border-top: 2px solid $color-red;
            }
        }
        & > .wpmm-nav-wrap{
            ul {
                display: inline-block;
                padding: 0;
                margin: 0;
                list-style: none;
                position: relative;
                left: auto;
                top: auto;
                & > li{
                    position: relative;
                    margin-right: 80px;
                    display: inline-block;
                    border-top: none;
                    &:hover{
                        &:before{
                            opacity: 1;
                        }
                    }
                    &.active{
                        & > a{
                            color: black;
                        }
                    }
                    &:before{
                        transition: 0.3s all ease-in-out;
                        opacity: 0;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        width: 100%;
                        height: 6px;
                        background-color: $color-red;
                    }
                    &.wpmm-social-link{
                        display: none;
                    }
                    //Link menu
                    & > a{
                        display: inline-block;
                        font-family: $font-text-bold;
                        font-size: 1rem;
                        font-weight: normal;
                        line-height: 16px;
                        color: #000;
                        text-transform: none;
                        padding: 40px 14px;
                        text-decoration: none;
                        &:hover,
                        &:focus{
                            color: $color-red;
                            text-decoration: none;
                        }
                        .fa-angle-down,
                        .wpmm_search_icon{
                            display: none;
                        }
                    }
                    &:hover{
                        & > a{
                            color: $color-red;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

button.burger{
    z-index: 10;
    cursor: pointer;
    position: fixed;
    top: 35px;
    right: 44px;
    width: 32px;
    height: 20px;
    padding: 0;
    border: 0;
    background: none;
    box-shadow: none;
    outline: none;
    &.burger--open{
        span{
            height: 0;
            &:before{
                top: 12px;
                transform: rotate(45deg);
            }
            &:after{
                top: 12px;
                transform: rotate(-45deg);
            }
        }
    }
    span{
        display: block;
        width: 100%;
        height: 3px;
        background-color: $color-red;
        &:before{
            transition: 0.3s all ease-in-out;
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 3px;
            background-color: $color-red;
        }
        &:after{
            transition: 0.3s all ease-in-out;
            content:"";
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: 100%;
            height: 3px;
            background-color: $color-red;
        }
    }
}

.menu-burger-open {
    overflow: hidden;
}

.header-right{
    z-index: 5;
    position: fixed;
    top: 0;
    right: 0;
    width: $header-right-width;;
    height: 100vh;
    background-color: #fff;
    .nav-primary{
        position: fixed;
        height: 0;
        .wp-megamenu-wrap .wpmm-nav-wrap > ul.wp-megamenu > li.wpmm_mega_menu:hover > .wpmm-strees-row-and-content-container > ul.wp-megamenu-sub-menu{
            animation-name:none;
            animation-duration: 0;
            display: none;
            top: auto;
            opacity: 0;
            z-index: -1;
            visibility: hidden;
        }
        .menu--open{
                &.wp-megamenu-wrap .wpmm-nav-wrap > ul.wp-megamenu > li.wpmm_mega_menu > .wpmm-strees-row-and-content-container > ul.wp-megamenu-sub-menu{
                display: block;
                top: 25px;
                opacity: 1;
                z-index: 1;
                visibility: visible;
            }
        }
        .submenu--open{
            position: absolute!important;
            left: auto!important;
            display: block!important;
            right: 85px!important;
            width: 288px!important;
        }
    }
    #wp-megamenu-primary_navigation{
        z-index: -2;
        background: none;
        .wpmm_brand_logo_wrap{
            display: none;
        }
        &.wp-megamenu-wrap .wpmm-nav-wrap > ul.wp-megamenu > li.wpmm_mega_menu > .wpmm-strees-row-and-content-container ul.wp-megamenu-sub-menu > li.wpmm-row > ul.wp-megamenu-sub-menu{
            max-width: 1320px;
            margin: 0 auto;
        }
        &.wp-megamenu-wrap .wpmm-nav-wrap>ul.wp-megamenu>li.wpmm_mega_menu>.wpmm-strees-row-and-content-container>ul.wp-megamenu-sub-menu{
            width: calc(100% - 120px);
            &:after{
                content: "";
                position: absolute;
                top: 0;
                right: -10px;
                display: block;
                width: 10px;
                height: 150%;
                background-color: #fff;
            }
        }
        .wpmm-strees-row-and-content-container{
            position: relative;
            top: 96px;
            & > .wp-megamenu-sub-menu{
                position: relative;
                top: 0;
            }
        }
        .wpmm-row{
            box-shadow: 5px 5px 6px 0.5px rgba(0,0,0, 0.1);
            &:first-child{
                border-top: 2px solid $color-red;
            }
        }
        & > .wpmm-nav-wrap{
            ul.wp-megamenu{
               & > li{
                   //Link menu
                   & > a{
                      font-family: $font-text-bold;
                      font-size: 1rem;
                      font-weight: normal;
                      line-height: 16px;
                      text-transform: none;
                      opacity: 0;
                      &:hover,
                      &:focus{
                          color: $color-red;
                          text-decoration: none;
                          & + .wp-megamenu-sub-menu{
                              display: none;
                          }
                      }
                      .fa-angle-down,
                      .wpmm_search_icon{
                          display: none;
                      }
                  }
                  &:hover{
                      & > a{
                          color: #000;
                          text-decoration: none;
                      }
                  }
                  li.wpmm-type-widget{
                      margin-bottom: 0;
                      padding: 8px 0;
                      position: initial;
                      &:hover{
                          &  > a{
                              font-family: $font-text-bold!important;
                              margin-left: 10px;
                              color: #000!important;
                              text-decoration: none;
                              .fa-angle-down{
                                  &:before{
                                      background-image: url(../images/arrow-menu-red.svg);
                                  }
                              }
                              // & + .wp-megamenu-sub-menu{
                              //     position: absolute;
                              //     left: auto;
                              //     display: block;
                              //     right: 85px;
                              //     width: 288px;
                              // }
                          }
                      }
                      //Link submenu
                      & > a{
                          display: inline-block;
                          width: fit-content!important;
                          font-family: $font-text-regular!important;
                          font-size: 0.875rem!important;
                          font-weight: normal;
                          line-height: 16px!important;
                          text-transform: none!important;
                          color: #000;
                          .fa-angle-down{
                              margin-left: 10px;
                              display: block;
                              &:before{
                                  content: "";
                                  display: block;
                                  width: 24px;
                                  height: 12px;
                                  margin-top: 4px;
                                  background-image: url(../images/arrow-menu.svg);
                                  background-repeat: no-repeat;
                                  background-size: contain;

                              }
                          }
                          & + .wp-megamenu-sub-menu{
                               display: none;
                               position: absolute;
                               top: 0;
                               right: 158px;
                               max-width: 288px;
                               li{
                                   a{
                                       font-family: $font-text-regular!important;
                                       font-size: 0.875rem;
                                       font-weight: normal;
                                       line-height: 16px;
                                       margin-bottom: 20px;
                                       text-transform: none;
                                       word-break: initial;
                                       color: #000;
                                   }
                                   &:hover{
                                       a{
                                           margin-left: 10px;
                                           color: #000!important;
                                           text-decoration: none;
                                       }
                                   }
                               }
                          }
                      }
                  }
                  ul.wp-megamenu-sub-menu{
                      background-color: $color-menu-gray!important;
                      .wpmm-col{
                          padding: 40px 0 40px 63.5px;
                      }
                      li:first-child{
                          &.wpmm-col {
                              border-right: 1px solid $color-text-grey;
                          }
                      }
                  }
                  //Content submenu
                  .wpmm-type-widget{
                      //Image
                      img{
                          display: block;
                          max-width: 288px!important;
                          margin: 0 auto;
                      }
                      //Title
                      .wpmm-item-title{
                          max-width: 288px;
                          margin: 0;
                          margin-bottom: 16px;
                          font-family: $font-text-bold!important;
                          font-size: 0.875rem;
                          font-weight: normal;
                          line-height: 24px;
                          text-transform: none;
                          color: $color-red;
                      }
                      //Text
                      .textwidget{
                          p{
                              max-width: 288px;
                              font-family: $font-text-regular;
                              font-size: 0.8125rem;
                              font-weight: normal;
                              line-height: 24px;
                              margin: 0!important;
                              margin-bottom: 16px!important;
                              a{
                                  font-family: $font-text-regular!important;
                                  font-size: 0.875rem!important;
                                  font-weight: normal;
                                  line-height: 16px!important;
                                  text-transform: none!important;
                                  &:after{
                                      content: "";
                                      width: 16px;
                                      height: 16px;
                                      display: inline-block;
                                      margin-left: 8px;
                                      background-image: url(../images/arrow-link.svg);
                                      background-repeat: no-repeat;
                                      background-size: contain;
                                  }
                              }
                          }
                      }
                  }
              }
          }
        }
    }
    .container-search{
        position: relative;
        .icon-search{
            z-index: -2;
            cursor: pointer;
            transition: all 0.2s ease-in;
            position: fixed;
            top: 102px;
            right: 44px;
            width: 24px;
            height: 24px;
            background-image: url(../images/search_grey.svg);
            background-repeat: no-repeat;
            background-size: contain;
            &--close{
                background-image: url(../images/close.svg);
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        .search-form{
            display: none;
            z-index: -2;
            position: absolute;
            top: 96px;
            left: -67vw;
            width: 67vw;
            padding: 28px 104px;
            background-color: $color-red;
        }
    }
    .twitter-logo{
        z-index: -2;
        position: fixed;
        top: 185px;
        right: 44px;
        display: block;
        width: 25px;
        height: 25px;
        background-image: url(../images/twitter.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .youtube-logo{
        z-index: -2;
        position: fixed;
        top: 260px;
        right: 44px;
        display: block;
        width: 25px;
        height: 25px;
        background-image: url(../images/youtube.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .facebook-logo{
        z-index: -2;
        position: fixed;
        top: 335px;
        right: 38px;
        display: block;
        width: 25px;
        height: 25px;
        background-image: url(../images/facebook.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .instagram-logo{
        z-index: -2;
        position: fixed;
        top: 400px;
        right: 44px;
        display: block;
        width: 25px;
        height: 25px;
        background-image: url(../images/instagram.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .nav-header{
        li{
            list-style-type: none;
        }
        .menu-contact{
            position: fixed;
            right: 25px;
            top: 500px;
            transform: rotate(-90deg);
            a{
                font-family: $font-text-bold;
                font-size: 0.875rem;
                font-weight: normal;
                line-height: normal;
                text-transform: uppercase;
                color: $color-text-grey;
            }
        }
        .menu-en,
        .menu-fr{
            position: fixed;
            right: 40px;
            bottom: 40px;
            a{
                padding: 11px 11px;
                font-family: $font-text-medium;
                font-size: 0.875rem;
                font-weight: normal;
                line-height: normal;
                text-align: center;
                text-transform: uppercase;
                background-color: $color-text-grey;
                color: #fff;
            }
        }
    }
}
