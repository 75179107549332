@media screen and (max-width: 1200px){
    header.banner {
        #wp-megamenu-first_navigation {
            & > .wpmm-nav-wrap {
                ul {
                    & > li {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px){
    #main-wrapper {
        margin-top: 90px;
    }
    header{
        .brand{
            margin-left: 0;
        }
        #wp-megamenu-first_navigation{
            display: none;
        }
    }
    header.banner{
        z-index: 5;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: #fff;
        padding-right: 0;
        .banner-content{
            max-width: auto;
        }
        .navigation-mobile{
            display: block;
        }
    }
    .header-right{
        display: none;
    }
    button.burger{
        width: 24px;
        height: 15px;
        top: 38px;
        right: 33px;
        &.burger-mobile--open {
            span{
                height: 0;
                &:before{
                    top: 12px;
                    transform: rotate(45deg);
                }
                &:after{
                    top: 12px;
                    transform: rotate(-45deg);
                }
            }
        }
        span{
            height: 2px;
            &:after,
            &:before{
                height: 2px;
            }
            &:before{
                transition: 0.3s all ease-in-out;
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 2px;
                background-color: $color-red;
            }
            &:after{
                transition: 0.3s all ease-in-out;
                content:"";
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                width: 100%;
                height: 2px;
                background-color: $color-red;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #footer{
        margin-top: 0;
        padding-right: 0;
        .nav-valorize{
            margin-top: 48px;
            margin-bottom: 48px;
        }
    }
}

@media screen and (max-width: 575px){
    h1 {
        font-size: 2.5rem;
        margin-bottom: 30px;
    }
    h2 {
        font-size: 1.25rem;
    }
    form {
        .field {
            label {
                font-size: 1.25rem;
            }
        }
        .conditions {
            label {
                font-size: 0.75rem;
            }
        }
    }
}
