.result-payment {
    p,
    h2 {
        text-align: center;
    }
    h2 {
        color: $color-red;
    }
    .button-wrapper {
        margin-top: 100px;
        text-align: center;
    }
}
