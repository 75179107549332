body {
    margin: 0;
    padding: 0;
    font-family: $font-text-regular;
    font-weight: normal;
    font-size: 16px;
}

a {
    text-decoration: none;
}

.hide {
    display: none;
}

#main-wrapper {
    background-color: #F8F7F6;
    padding: 24px 0 80px;
    position: relative;
    overflow: hidden;
    &:before {
        content: "Paiement";
        font-family: $font-text-black;
        font-weight: normal;
        font-size: 160px;
        color: #fff;
        text-transform: uppercase;
        position: absolute;
        top: 25px;
        right: 0;
        z-index: 0;
    }
    & > .container {
        position: relative;
        z-index: 1;
    }
}

.breadcrumbs {
    list-style-type: none;
    padding: 0;
    li {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #000;
        &:last-child {
            margin-right: 0;
            border: 0;
        }
    }
    a,
    span {
        display: block;
        font-family: $font-text-medium;
        font-weight: normal;
        font-size: 0.75rem;
        color: #000;
        text-decoration: none;
    }
}

.btn {
    font-family: $font-text-bold;
    font-weight: normal;
    font-size: 0.875rem;
    color: #fff;
    background-color: $color-red;
    padding: 15px 25px;
    border: 0;
    border-radius: 4px;
    margin-top: 40px;
    transition: all 300ms ease;
    cursor: pointer;
    &:hover {
        background-color: $color-dark-red;
    }
    &:disabled {
        background-color: $color-book-gray;
        cursor: not-allowed;
    }
}
